import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CircularProgress,
  Grid,
  LinearProgress,
  Stack,
  Typography,
  Tooltip,
} from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import NotesIcon from '@mui/icons-material/Notes';
import HistoryIcon from '@mui/icons-material/History';
import FingoDialog from './FingoDialog';
import { STAFF_MASTER_ENTITY_PROFILE, CREATE_CREDIT_LINE_EVALUATION_REQUESTS } from '../../graphql';
import { DebtorFactoringInformation } from '../risk';
import { CompanyHasCredentialsCell, CustomerDocumentsCellAccordion } from '../cells';
import CompanyBlacklistComponent from '../cells/CompanyBlacklistComponent';
import CompanyCessionsTooltip from '../cells/CompanyCessionTooltip';
import { formatMoney } from '../../helpers';
import { CONTACT_COLUMNS } from '../../constants/contact-columns';
import { AvatarCell } from '../dataGridCells';
import RatificationInformationIconButton from '../icon-buttons/RatificationNotesIconButton';
import ContactCard from '../cards/ContactCard';
import useSnackBars from '../../hooks/useSnackBars';
import { CreditLineSection } from '../creditlines';

const DebtorProfileDialog = ({ open, setOpen, masterEntityId }) => {
  const { data, loading } = useQuery(STAFF_MASTER_ENTITY_PROFILE, {
    variables: { masterEntityId },
    skip: !open,
  });
  const masterEntity = data?.staffMasterEntity;
  const { addAlert } = useSnackBars();
  const [createCreditLineEvaluationRequests, { loading: mutationLoading }] = useMutation(
    CREATE_CREDIT_LINE_EVALUATION_REQUESTS,
    {
      refetchQueries: [STAFF_MASTER_ENTITY_PROFILE],
      onCompleted: () => {
        addAlert({
          id: 'credit-line-evaluation-request',
          message: 'Solicitud de evaluación de línea creada',
          severity: 'success',
          color: 'success',
        });
      },
      onError: (error) => {
        addAlert({
          id: 'credit-line-evaluation-request-error',
          message: `Error al crear solicitud de evaluación de línea: ${error.message}`,
          severity: 'error',
          color: 'error',
        });
      },
    },
  );

  const creditLines = masterEntity?.creditLinesByType ?? [];

  return useMemo(() => (
    <FingoDialog
      title="Perfil Empresa"
      open={open}
      handleClose={setOpen}
      maxWidth="xl"
      fullWidth
      sx={{ zIndex: 1100 }}
    >
      {!masterEntity
        ? (
          <Stack sx={{ alignItems: 'center' }}>
            <CircularProgress size={30} />
          </Stack>
        ) : (
          <Grid container>
            <Grid
              container
              item
              xs={12}
              width="100%"
              borderRadius={5}
              bgcolor="gray.A100"
              py={2}
              px={2}
            >
              <Grid item xs={6}>
                <Stack spacing={2}>
                  <Typography variant="h6" fontWeight="bold" sx={{ color: '#323232' }}>
                    {masterEntity.name} {masterEntity.displayNationalIdentifier}
                  </Typography>
                  <CompanyBlacklistComponent masterEntity={masterEntity} disableDisplay />
                  <CreditLineSection
                    creditLines={creditLines}
                    masterEntityId={masterEntityId}
                    createCreditLineEvaluationRequests={createCreditLineEvaluationRequests}
                    isLoading={mutationLoading}
                  />
                  {masterEntity.company && (
                  <CustomerDocumentsCellAccordion
                    masterEntity={masterEntity}
                  />
                  )}
                </Stack>
              </Grid>
              <Grid item xs={2.5}>
                <Stack direction="row" spacing={1}>
                  <CompanyCessionsTooltip company={masterEntity?.company}>
                    <NotesIcon />
                  </CompanyCessionsTooltip>
                  <Tooltip title="Historial">
                    <HistoryIcon />
                  </Tooltip>
                  {!masterEntity?.debtor?.ratificationInformation && (
                    <RatificationInformationIconButton
                      masterEntityId={masterEntity.id}
                    />
                  )}
                </Stack>
                <Stack spacing={1} direction="column" sx={{ maxWidth: 150, marginTop: 3 }}>
                  <Typography variant="h6" sx={{ color: '#323232' }}>
                    Credenciales
                  </Typography>
                  {masterEntity.company ? (
                    <CompanyHasCredentialsCell company={masterEntity.company} />
                  ) : (
                    'Sin credenciales'
                  )}
                </Stack>
                <Stack spacing={1} direction="column" sx={{ maxWidth: 90, marginTop: 1 }}>
                  <Typography variant="h6" sx={{ color: '#323232' }}>
                    Excedentes
                  </Typography>
                  <Typography variant="h6">
                    ${formatMoney(masterEntity.company?.currentSurplusBalanceValue)}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={3.5}>
                <ContactCard
                  masterEntity={masterEntity}
                  columns={CONTACT_COLUMNS}
                />
                <Stack direction="column" spacing={1} mt={3}>
                  <Stack direction="row">
                    <Typography variant="h6" sx={{ color: '#323232', width: '100%', marginTop: 1.5 }}>
                      Ejecutivo Asignado
                    </Typography>
                    <AvatarCell user={masterEntity.company?.executiveAssigned} />
                  </Stack>
                  <Stack direction="row">
                    <Typography variant="h6" sx={{ color: '#323232', width: '100%', marginTop: 1.5 }}>
                      Ejecutivo Ratificación
                    </Typography>
                    <AvatarCell user={masterEntity?.debtor?.ratificator} />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ width: '100%', height: 600, mt: 2 }}>
                <Typography variant="h6" fontWeight="bold" sx={{ color: '#323232' }}>
                  Información como deudor
                </Typography>
                {loading ? <LinearProgress /> : <DebtorFactoringInformation masterEntityId={masterEntityId} type="receiver" />}
              </Box>
            </Grid>
          </Grid>
        )}
    </FingoDialog>
  ), [masterEntity]);
};

DebtorProfileDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  masterEntityId: PropTypes.string.isRequired,
  showDicom: PropTypes.bool,
};

DebtorProfileDialog.defaultProps = {
  showDicom: true,
};

export default DebtorProfileDialog;
